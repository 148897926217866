export const isTouchEnabled = () => {
    return ( 'ontouchstart' in window ) ||
           ( navigator.maxTouchPoints > 0 ) ||
           ( navigator.msMaxTouchPoints > 0 );
}

export const enableBodyScroll = () => {
    document.body.classList.remove('no-scroll');
    document.body.style.width = '';
};

export const disableBodyScroll = () => {
    document.body.style.width = document.body.clientWidth + 'px';
    document.body.classList.add('no-scroll');
};

export const setSiteHeaderWhite = showWhiteSiteHeader => {
    const $siteHeader = document.querySelector('.site-header');

    if (!$siteHeader) return;

    if (showWhiteSiteHeader) {
        $siteHeader.classList.add('site-header--white');
    } else {
        $siteHeader.classList.remove('site-header--white');
    }
};
