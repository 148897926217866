(() => {
    let $anchorLinks = document.querySelectorAll('a');

    $anchorLinks = $anchorLinks ? Array.from($anchorLinks).filter($anchorLink => {
        let url = null;

        try {
            url = new URL($anchorLink.href);
        } catch (err) {
            return;
        }

        return url?.hash && document.querySelector(url?.hash);
    }) : $anchorLinks;

    if (!$anchorLinks) return;

    $anchorLinks.forEach($anchorLink => {
        $anchorLink.addEventListener('click', e => {
            const url = new URL($anchorLink.href);

            const $targetEl = url ? document.querySelector(url?.hash) : null;

            if (!$targetEl) return;

            e.preventDefault();

            console.log($targetEl?.getBoundingClientRect()?.y, window.scrollY);

            window.scrollTo({
                top: $targetEl?.getBoundingClientRect()?.y + window.scrollY,
                behavior: 'smooth',
            });
        });
    });
})();
